import { Controller } from 'framewerk';
import $ from 'jquery';
import {
  controllerSelector,
  executeGet,
  findParentNodeByTag,
  kebabToCamel,
  requestTemplate,
  STATES
} from '../shared';
import { render } from 'mustache';

const { AccordionMenu } = Foundation;

type Context = 'trainings' | 'evaluations' | 'certifications' | 'methods';
type EvaluationType = 'training' | 'evaluation';

interface EvaluationRecord {
  categoryName: string;
  name: string;
  date: string;
  aashtoCode: string;
  astmCode: string;
  comments: string;
  intervalTime: string;
  intervalUnit: number;
}

interface Evaluation {
  title: string;
  evaluationType: EvaluationType;
  idCode: string;
  label: string;
  isComplete: boolean;
  showRoute: string;
  editRoute: string;
  pdfRoute: string;
  userId: string;
  showOverlayTitle: string;
  records?: EvaluationRecord[];
}

interface Certification {
  name: string;
  date: string;
  period: string;
  showRoute: string;
  editRoute: string;
}

interface CreateOrUpdatePayload {
  userId: number;
  userRow: string;
  sectionWrapper?: string;
  evaluationType?: 'training' | 'evaluation';
  evaluations?: string;
  certifications?: string;
  methods?: string;
}

enum evaluatorTypeEnum {
  INTERNAL = 'internal',
  OUTSIDE = 'outside'
}

/**
 * The initialization function for creating the `r18-procedure-evaluation` Controller.
 */
export function procedureEvaluation(): Controller {
  let accordionMenus: any = [];

  /**
   * Determines whether the form is being viewed in an overlay or as a standalone form.
   * The standalone version is linked from the dashboard.
   * @private
   */
  const IS_STANDALONE =
    new URLSearchParams(window.location.search).get('context') === 'dashboard';

  /**
   * The name of the controller.
   */
  const name: string = 'r18-procedure-evaluation';

  /**
   * Factory method for generating selectors.
   */
  const selector = controllerSelector(name);

  /**
   * Selector strings for the `r18-procedure-evaluation` Controller.
   */
  const selectors = {
    back: selector('back'),
    cancel: selector('cancel'),
    continue: selector('continue'),
    tabs: selector('tabs'),
    accordion: selector('accordion', false, 'ul'),
    toggleLink: selector('toggle-link', false, 'a'),
    summaryLink: selector('summary-link', false, 'a'),
    toggleTarget: selector('toggle-target'),
    contextTargets: selector('context-target', false, 'a'),
    userTargets: selector('user-target'),
    userEvaluationCounts: selector('user-evaluation-count'),
    userCertificationCounts: selector('user-certification-count'),
    summaryTableTemplate: selector('summary-table-template', false, 'script'),
    evaluatorType: selector('evaluator-type'),
    evaluatorField: selector('evaluator-field'),
    existingOutsideEvaluator: selector('existing-outside-evaluator'),
    outsideEvaluatorSelect: selector('outside-evaluator-select')
  };

  /**
   * Element collections for targeting.
   */
  const targets = Controller.getTargets(selectors) as Record<
    keyof typeof selectors,
    Array<HTMLElement>
  >;

  const listElements = ['evaluations', 'certifications', 'methods'].reduce(
    (elements, key) => ({
      ...elements,
      [key]: {
        template: document.querySelector<HTMLTemplateElement>(
          selector(`${key}-template`, false, 'script')
        ).innerHTML,
        path: document.querySelector<HTMLInputElement>(
          selector(`${key}-path`, false, 'input')
        ).value
      }
    }),
    {} as Record<
      Context,
      {
        template: string;
        path: string;
      }
    >
  );

  /**
   * Events created for the `r18-procedure-evaluation` Controller.
   */
  const events = {
    init: () => {
      const input = targets.evaluatorType.find(
        (target: HTMLInputElement) => target.checked
      ) as HTMLInputElement;
      if (input) {
        toggleField(input.value as evaluatorTypeEnum);
      }

      const params = new URLSearchParams(window.location.search);
      const highlightContext = params.get('highlight');
      const highlightTarget = params.getAll('highlight_user_ids[]');

      if (highlightContext && highlightTarget) {
        handleSetHighlightState(highlightContext, highlightTarget);
      }

      setDisabledRows();
    },
    tabsEvents: () => {
      // we have to use a jQuery event here
      $(targets.tabs).on('change.zf.tabs', (_, tab: JQuery<HTMLLIElement>) => {
        const tabType = tab.data('r18-procedure-evaluation-tab');
      });
    },
    buttonEvents: () => {
      const $tabs = $(targets.tabs[0]);
      targets.continue[0].addEventListener('click', () => {
        $tabs.foundation('selectTab', $('#tab_generalEvaluation'));
      });

      targets.back[0].addEventListener('click', () => {
        $tabs.foundation('selectTab', $('#tab_evaluationReport'));
      });
    },
    updateEvents: () => {
      // prettier-ignore
      window.addEventListener("procedureEvaluation:create", handleCreateOrUpdate);
      // prettier-ignore
      window.addEventListener("procedureEvaluation:update", handleCreateOrUpdate);
      // prettier-ignore
      window.addEventListener("technicianTraining:create", handleCreateOrUpdate);
      // prettier-ignore
      window.addEventListener("technicianTraining:update", handleCreateOrUpdate);
      // prettier-ignore
      window.addEventListener("technicianCertification:create", handleCreateOrUpdate);
      // prettier-ignore
      window.addEventListener("technicianCertification:update", handleCreateOrUpdate);
      window.addEventListener('userTestMethods:create', handleCreateOrUpdate);
      window.addEventListener('userTestMethods:update', handleCreateOrUpdate);
    },
    accordionMenuEvents: () => {
      targets.accordion.forEach(target => {
        target.addEventListener('click', async (event: MouseEvent) => {
          let eventTarget = event.target as HTMLElement;
          if (eventTarget.tagName !== 'A') {
            eventTarget = findParentNodeByTag(eventTarget, 'a');
          }

          if (eventTarget?.hasAttribute(`data-${name}-summary-link`)) {
            event.preventDefault();
            await handleSummaryLinkClick(eventTarget as HTMLAnchorElement);
          }

          if (eventTarget?.hasAttribute(`data-${name}-context-target`)) {
            event.preventDefault();
            await handleContextTargetClick(eventTarget);
          }
        });
      });
    },
    formEvents: () => {
      targets.evaluatorType.forEach(target => {
        target.addEventListener('change', event => {
          toggleField(
            (event.target as HTMLInputElement).value as evaluatorTypeEnum
          );
        });
      });

      // we have to use a jQuery event to listen to Select2
      $(targets.outsideEvaluatorSelect[0]).on(
        'change.select2',
        (event: JQuery.TriggeredEvent) => {
          setExistingOutsideEvaluatorField(
            (event.target as HTMLInputElement).value
          );
        }
      );
    }
  };

  return new Controller({ name, targets, events });

  /////////////////

  function handleCreateOrUpdate({
    detail,
    type
  }: CustomEvent<CreateOrUpdatePayload>) {
    const {
      userId,
      userRow,
      sectionWrapper,
      evaluationType,
      certifications,
      evaluations,
      methods
    } = detail;
    const renderedUserRow = updateUserRow(userId, userRow);
    const [modelName, actionName] = type.split(':');

    let contextTarget: Context;
    let collection: Array<unknown>;
    let insertPosition: 'afterbegin' | 'beforeend';

    switch (modelName) {
      case 'technicianTraining':
        contextTarget = 'trainings';
        collection = JSON.parse(evaluations || '[]');
        insertPosition = 'afterbegin';
        break;
      case 'procedureEvaluation':
        contextTarget = 'evaluations';
        collection = JSON.parse(evaluations || '[]');
        insertPosition = 'afterbegin';
        break;
      case 'technicianCertification':
        contextTarget = 'certifications';
        collection = JSON.parse(certifications || '[]');
        insertPosition = 'beforeend';
        break;
      case 'userTestMethods':
        contextTarget = 'methods';
        insertPosition = 'beforeend';
        break;
    }

    const targetMenu = renderedUserRow.nextElementSibling;
    const target = targetMenu.querySelector(
      `[data-r18-procedure-evaluation-context-target="${contextTarget}"]`
    )?.nextElementSibling;

    switch (actionName) {
      case 'create':
        if (!target) {
          targetMenu.insertAdjacentHTML(insertPosition, sectionWrapper);

          if (contextTarget === 'methods') {
            const menu = targetMenu.querySelector('ul.menu.vertical.nested');
            if (menu) {
              menu.innerHTML = methods;
            }
          }
        } else if (!sectionIsLoading(target)) {
          if (contextTarget === 'methods') {
            target.innerHTML = methods;
          } else {
            target.innerHTML = buildList(contextTarget, collection);
          }
        }
        break;
      case 'update':
        if (contextTarget === 'methods') {
          target.innerHTML = methods;
        } else {
          if (collection.length === 0) {
            target.parentElement.remove();
          } else if (target && !sectionIsLoading(target)) {
            target.innerHTML = buildList(contextTarget, collection);
          }
        }
        break;
    }

    Foundation.reInit('accordion-menu');
    setDisabledRows();
  }

  async function handleSummaryLinkClick(target: HTMLAnchorElement) {
    const idCode = target.dataset.r18ProcedureEvaluationSummaryLink;
    const tableTarget = document.getElementById(
      `procedureEvaluationTableTarget-${idCode}`
    );

    if (!tableTarget?.children.length) {
      target.classList.add(STATES.isActive);

      const evaluation = (await executeGet(target.href)) as Evaluation;

      if (tableTarget) {
        tableTarget.innerHTML = buildEvaluationSummaryTable(evaluation);
      }

      target.classList.remove(STATES.isActive);
    }

    requestAnimationFrame(() => {
      const tableIsHidden = tableTarget.classList.contains(STATES.isHidden);
      target.textContent = I18n.t(
        `evaluations.buttons.${tableIsHidden ? 'show' : 'hide'}_summary`
      );
    });
  }

  async function handleContextTargetClick(target: HTMLElement) {
    if (sectionIsLoading(target.nextElementSibling as HTMLElement)) {
      target.classList.add(STATES.isDisabled);
      // prettier-ignore
      const context = target.dataset[`${kebabToCamel(name)}ContextTarget`] as Context;
      const userId = target.dataset[`${kebabToCamel(name)}UserTarget`];

      if (context && userId) {
        let template = '';

        try {
          const listOrTemplate = await getListOrTemplate(context, userId);
          if (context === 'methods') {
            template = listOrTemplate;
          } else {
            template = buildList(context, listOrTemplate);
          }

          target.nextElementSibling.innerHTML = template;
          $(target.nextElementSibling).foundation();
        } catch (error) {
          //   do something!?
        } finally {
          target.classList.remove(STATES.isDisabled);
        }
      }
    }
  }

  /**
   * Renders a single {@link Evaluation} as a table by rendering it
   * through Mustache.
   *
   * @param evaluation
   */
  function buildEvaluationSummaryTable(evaluation: Evaluation) {
    const template = targets.summaryTableTemplate[0].innerHTML;
    return render(template, evaluation);
  }

  /**
   * Check if a specific section has loaded yet.
   *
   * @param section
   */
  function sectionIsLoading(section: Element) {
    /*
      only load the data if it hasn't been loaded before.
      we call `textContent` here to strip away all of the markup.
      this is a little hacky, but should be reliable
    */
    return (
      section.textContent.trim() ===
      I18n.t('global.labels.loading.with_ellipses')
    );
  }

  /**
   * Updates a user's summary row in the accordion. Returns the up
   *
   * @param userId The ID of the user.
   * @param userRow HTML to replace the content of the user row.
   */
  function updateUserRow(userId: number, userRow: string) {
    const target = targets.userTargets.find(
      target =>
        target.dataset.r18ProcedureEvaluationUserTarget === userId.toString()
    );

    if (target) {
      target.innerHTML = userRow;
    }

    return target;
  }

  /**
   * Toggles the two field sections, disabling fields within the hidden section to prevent submission.
   *
   * @param type The active field.
   */
  function toggleField(type: evaluatorTypeEnum) {
    targets.evaluatorField.forEach(target => {
      const isMatch =
        target.dataset.r18ProcedureEvaluationEvaluatorField === type;

      target.classList.toggle(STATES.isHidden, !isMatch);
      target
        .querySelectorAll('input, select')
        .forEach((element: HTMLInputElement | HTMLSelectElement) => {
          element.disabled = !isMatch;
        });
    });
  }

  /**
   * Disables any user rows which have no evaluation records of any kind on file.
   */
  function setDisabledRows() {
    targets.userTargets.forEach(target => {
      target.classList.toggle(
        'not-clickable',
        target.nextElementSibling.children.length === 0
      );
    });
  }

  /**
   * Sets the `existingOutsideEvaluator` field's disabled state based on the provided value.
   * Disabling the field excludes it from the POST payload.
   *
   * @param value
   */
  function setExistingOutsideEvaluatorField(value: string) {
    (targets.existingOutsideEvaluator[0] as HTMLInputElement).disabled =
      Number.isNaN(Number(value));
  }

  /**
   * Initiates a click but returns a promise which must be resolved after
   * the click has happened.
   *
   * @param {HTMLElement} target The object to click.
   *
   * @return {Promise}
   */
  function deferredClick(target: HTMLElement): Promise<void> {
    return new Promise(resolve => {
      target.click();
      resolve();
    });
  }

  /**
   * Given a collection of user IDs and a context, finds appropriate click targets
   * in the accordion and clicks them (if found), then applies a visual highlight
   * to the appropriate row(s).
   *
   * @param context Either `evaluations` or `certifications`.
   * @param users A collection of user IDS.
   */
  async function handleSetHighlightState(context: string, users: string[]) {
    interface ClickTargets {
      primary: HTMLElement | null;
      secondary: HTMLElement | null;
    }

    const clickTargets = users.map<ClickTargets>(user => {
      const potentialTargets = targets.userTargets.filter(
        target => target.dataset.r18ProcedureEvaluationUserTarget === user
      );

      return {
        primary: potentialTargets.find(
          target => !target.dataset.r18ProcedureEvaluationContextTarget
        ),
        secondary: potentialTargets.find(
          target =>
            target.dataset.r18ProcedureEvaluationContextTarget === context
        )
      };
    });

    let promises: Promise<void>[] = [];

    clickTargets.forEach(group => {
      promises.push(
        new Promise(async resolve => {
          if (group.primary) {
            await deferredClick(group.primary);
          }

          if (group.secondary) {
            await deferredClick(group.secondary);
          }

          resolve();
        })
      );
    });

    await Promise.all(promises);

    setTimeout(() => {
      clickTargets.forEach(target => {
        target.primary.parentElement.classList.add('highlighted');
      });
    }, 750);
  }

  /**
   * Requests a collection of data via a linked context.
   *
   * @param context
   * @param userId
   */
  async function getListOrTemplate(context: Context, userId: string) {
    let path;

    // trainings uses the same path as evaluations
    if (context === 'trainings') {
      path = listElements.evaluations.path;
    } else {
      path = listElements[context].path;
    }

    let url = new URL(path, window.location.origin);

    url.searchParams.set('user_id', userId.toString());

    if (context === 'evaluations' || context === 'trainings') {
      url.searchParams.set('evaluation_type', context.slice(0, -1));
    }

    if (context === 'methods') {
      return requestTemplate(
        url.toString(),
        '*[id*="user_test_methods_table"]'
      );
    }

    return executeGet(url.toString());
  }

  /**
   * Renders a collection of items via a linked template.
   *
   * @param context
   * @param collection
   */
  function buildList(context: Context, collection: Array<unknown>) {
    if (context === 'trainings') {
      context = 'evaluations';
    }
    return collection
      .map(item => render(listElements[context].template, item))
      .join('');
  }
}
